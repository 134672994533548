/* eslint-disable */

export const getSrcSet = (set) => {
	let image = "";
	for (let i in set) {
		const img = set[i];
		const num = parseInt(i);
		if (num !== 0) {
			image += ", ";
		}
		image += `${img} ${num + 1}x`;
	}
	return image;
};

export const getBackgroundSet = (set) => {
	let image = "";
	for (let i in set) {
		const img = set[i];
		const num = parseInt(i);
		if (num !== 0) {
			image += ", ";
		}
		image += `url(${img}) ${num + 1}x`;
	}
	return image;
};

export default {
	getSrcSet,
	getBackgroundSet,
};
