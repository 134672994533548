import React from "react";
import axios from "axios";
import moment from "moment";
import NumberFormat from "react-number-format";

const utils = {};

utils.isEmail = (email) => {
	if (!email) {
		return false;
	}
	const reg = /[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}/gim;
	if (reg.test(email.toLowerCase())) {
		return true;
	}
	return false;
};

utils.encode = (o) => {
	return btoa(encodeURIComponent(JSON.stringify(o)));
};

utils.decode = (str) => {
	return JSON.parse(decodeURIComponent(atob(str)));
};

utils.setSession = (code, value) => {
	window.sessionStorage.setItem(code, utils.encode(value));
};

utils.getSession = (code) => {
	if (window.sessionStorage.getItem(code)) {
		return utils.decode(window.sessionStorage.getItem(code));
	}
	return false;
};

utils.removeSession = (code) => {
	window.sessionStorage.removeItem(code);
};

utils.postMessage = (code, data, target) => {
	if (!target) {
		target = window;
	}
	target.postMessage(JSON.stringify({ code: code, data: data }), window.location.origin);
};

utils.parseMessage = (e) => {
	return new Promise((resolve, reject) => {
		if (!e.data) {
			reject && reject();
			return;
		}
		let data = null;
		try {
			data = typeof e.data === "string" && e.data.startsWith("{") ? JSON.parse(e.data) : e.data;
		} catch (e) {
			reject && reject();
			return;
		}
		try {
			if (data && data.code) {
				resolve && resolve(data);
			}
		} catch (e) {
			reject && reject(e);
		}
	});
};

utils.getGEO = (key, addr) => {
	return new Promise((resolve, reject) => {
		axios
			.get(`https://maps.googleapis.com/maps/api/geocode/json?key=${key}&sensor=false&language=ko&address=${addr}`)
			.then((res) => {
				if (res.status !== 200) {
					throw new Error(res.statusText);
				}

				const { data } = res;
				if (data.results.length > 0) {
					const locate = data.results[0].geometry.location;
					resolve(locate);
				} else {
					throw new Error("GEO 정보를 확인 할 수 없는 주소 입니다.");
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
};

utils.getDefinedLabel = (defineds, value, nullText) => {
	if (!defineds || !value) return "";
	let returnText = "";
	defineds?.map((item, i) => {
		if (item.value === value) {
			returnText = item.label;
		}
	});
	return returnText ? returnText : nullText || "";
};

utils.shortText = (text, maxLength, tail) => {
	if (!text || !maxLength) return "";
	let shortText = [];
	for (let i = 0; i < text.length && i < maxLength; i++) {
		shortText.push(text.charAt(i));
	}
	return shortText.join("") + tail;
};

utils.getAge = (birthday) => {
	if (!birthday) {
		return "";
	}

	const today = new Date();
	const birthDate = new Date(moment(birthday, "YYYYMMDD")); // 2000년 8월 10일

	let age = today.getFullYear() - birthDate.getFullYear();
	const m = today.getMonth() - birthDate.getMonth();
	if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
		age--;
	}

	return `만 ${age}세`;
};

utils.numberFormat = (number) => {
	if (!number) {
		return 0;
	}

	return <NumberFormat value={number} displayType={"text"} thousandSeparator={true} prefix={""} />;
};

utils.addComma = (num) => {
	if (!num) {
		return 0;
	}
	var regexp = /\B(?=(\d{3})+(?!\d))/g;
	return num.toString().replace(regexp, ",");
};

utils.changePercent = (den, mol, dp) => {
	if (!den || !mol) {
		return "0";
	}

	if (dp) {
		return ((den / mol) * 100).toFixed(dp) + "%";
	} else {
		return (den / mol) * 100 + "%";
	}
};

utils.makeDayFromMin = (min) => {
	if (!min) {
		return;
	}

	const days = Math.floor(min / 60 / 24);
	const hours = Math.floor((min - days * 60 * 24) / 60);
	const mins = min - days * 60 * 24 - hours * 60;

	const daysStr = days;
	const hourStr = hours > 9 ? hours : hours;
	const minStr = mins > 9 ? mins : mins;

	let result = "";
	if (daysStr) {
		result += `${daysStr}일 `;
	}
	if (hourStr) {
		result += `${hourStr}시간 `;
	}

	result += `${minStr}분`;

	return result;
};

utils.formatBytes = (bytes, decimals = 2) => {
	if (bytes === 0) return "0 Bytes";

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

utils.lpad = (ori, add, len) => {
	if (!len || !add) return "";
	if (ori === null || ori === undefined) return ""; //숫자 0일경우 false가 되므로 따로 처리
	const data = ori + "";
	const addList = [];
	for (let i = 0; i < len - data.length; i++) {
		addList.push(add);
	}
	return addList.join("") + data;
};

utils.shortEncode = (str) => {
	if (!str) {
		return "";
	}

	const buff = new Buffer(str);
	const stringToBase64 = buff.toString("base64");

	return stringToBase64;
};

utils.isSizeGuide = (categories) => {
	const result = categories.some((item) => {
		return item.label === "의류" || item.label === "신발" || item.label === "슈즈" || item.label === "악세서리" || item.label === "액세서리";
	});

	return result;
};

utils.selectGuide = (categories, items) => {
	const main = categories[0].label;
	const sub = categories[1]?.label || categories[0].label;

	const subResult = items.sub.some((item) => {
		return item === sub;
	});

	if (items.main && main) {
		return main === items.main && subResult;
	} else {
		return subResult;
	}
};

export default utils;
