/* eslint-disable */
import React, { useState } from "react";
import axios from "axios";
import swal from "sweetalert";

export default (props) => {
	const [data, setData] = useState(null);

	const onClickContact = () => {
		if (!data?.name) {
			swal({ title: "알림", text: "이름을 입력하세요." });
			return;
		}
		if (!data?.email) {
			swal({ title: "알림", text: "이메일주소를 입력하세요." });
			return;
		}
		if (!data?.subject) {
			swal({ title: "알림", text: "제목을 입력하세요." });
			return;
		}
		if (!data?.content) {
			swal({ title: "알림", text: "내용을 입력하세요." });
			return;
		}

		axios({
			method: "post",
			url: "https://api.dm9.io/v1/email/send",
			headers: {
				apikey: "DMS-JMDO-20201127-#UE7&CZ",
			},
			data: {
				sender: data?.email,
				senderName: data?.name,
				receiver: "jmdo@dmon9.com",
				receiverName: "WEAVERM",
				title: `[WEAVERM 문의] ${data?.subject}`,
				content: data?.content,
			},
		}).then((res) => {
			swal({ title: "알림", text: "문의 내용이 정상 접수 되었습니다. 확인 후 연락 드리겠습니다." }).then(() => {
				setData({ name: "", email: "", subject: "", content: "" });
			});
		});
	};

	return (
		<section className="bg-white sp-100 contact-section" id="contact">
			<div className="container">
				<div className="row">
					<div className="col-12">
						<h3 className="btm-sep pb-3 mb-60">Contact Us</h3>
					</div>
					<div className="col-lg-8 mb-60 mb-lg-0">
						<div className="bg-white contact-form">
							<form className="form">
								<div className="row">
									<div className="col-md-12">
										<div id="error_contact"></div>
									</div>
									<div className="col-md-6">
										<div className="form-group position-relative">
											<span className="zmdi zmdi-account"></span>
											<input type="text" className="form-control" placeholder="이름" id="name" value={data?.name} onChange={(e) => setData({ ...data, name: e.target.value })} />
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group position-relative">
											<span className="zmdi zmdi-email"></span>
											<input
												type="text"
												className="form-control"
												placeholder="이메일주소"
												id="email"
												value={data?.email}
												onChange={(e) => setData({ ...data, email: e.target.value })}
											/>
										</div>
									</div>
									<div className="col-12">
										<div className="form-group position-relative">
											<span className="zmdi zmdi-border-color"></span>
											<input
												type="text"
												className="form-control"
												placeholder="제목"
												id="subject"
												value={data?.subject}
												onChange={(e) => setData({ ...data, subject: e.target.value })}
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group">
											<textarea
												rows="8"
												className="form-control"
												placeholder="내용"
												id="message"
												value={data?.content}
												onChange={(e) => setData({ ...data, content: e.target.value })}
											></textarea>
										</div>
									</div>
									<div className="col-md-12">
										<button type="button" className="btn btn-lg btn-custom btn-dark" id="contact-submit" onClick={onClickContact}>
											<i className="fa fa-paper-plane"></i> 문의하기
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
					<div className="col-lg-4 mb-30">
						<div className="theme-map">
							<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3163.7827900023344!2d127.13838461558792!3d37.536617233598115!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357cb01db7db8a4d%3A0x4bc41888ea5206cd!2z7ISc7Jq47Yq567OE7IucIOqwleuPmeq1rCDquLjrj5kgNDEwLTE!5e0!3m2!1sko!2skr!4v1653378102310!5m2!1sko!2skr"></iframe>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
