/* eslint-disable */
import React from "react";
import { getSrcSet } from "../../UI/Assets";

import "../../Resources/scss/about.scss";

export default (props) => {
	return (
		<section className="about-section sp-100-70" id="about" style={{ backgroundColor: "#e4e4e4" }}>
			<div className="container">
				<div className="clients">
					<div className="client-slider" id="client-slider">
						<div className="item">
							<img src="/images/amgen.png" alt="clients" srcSet={getSrcSet[("/images/amgen.png", "/images/amgen@2x.png", "/images/amgen@3x.png")]} />
						</div>
						<div className="item">
							<img src="/images/BMS.png" alt="clients" srcSet={getSrcSet[("/images/BMS.png", "/images/BMS@2x.png", "/images/BMS@3x.png")]} />
						</div>
						<div className="item">
							<img src="/images/CANON.png" alt="clients" srcSet={getSrcSet[("/images/CANON.png", "/images/CANON@2x.png", "/images/CANON@3x.png")]} />
						</div>
						<div className="item">
							<img src="/images/ELSAL.png" alt="clients" srcSet={getSrcSet[("/images/ELSAL.png", "/images/ELSAL@2x.png", "/images/ELSAL@3x.png")]} />
						</div>
						<div className="item">
							<img src="/images/GE.png" alt="clients" srcSet={getSrcSet[("/images/GE.png", "/images/GE@2x.png", "/images/GE@3x.png")]} />
						</div>
						<div className="item">
							<img src="/images/hanyang.png" alt="clients" srcSet={getSrcSet[("/images/hanyang.png", "/images/hanyang@2x.png", "/images/hanyang@3x.png")]} />
						</div>
						<div className="item">
							<img src="/images/karms.png" alt="clients" srcSet={getSrcSet[("/images/karms.png", "/images/karms@2x.png", "/images/karms@3x.png")]} />
						</div>
						<div className="item">
							<img src="/images/MENARINI.png" alt="clients" srcSet={getSrcSet[("/images/MENARINI.png", "/images/MENARINI@2x.png", "/images/MENARINI@3x.png")]} />
						</div>
						<div className="item">
							<img src="/images/PFIZER.png" alt="clients" srcSet={getSrcSet[("/images/PFIZER.png", "/images/PFIZER@2x.png", "/images/PFIZER@3x.png")]} />
						</div>
						<div className="item">
							<img src="/images/ROCHE.png" alt="clients" srcSet={getSrcSet[("/images/ROCHE.png", "/images/ROCHE@2x.png", "/images/ROCHE@3x.png")]} />
						</div>
						<div className="item">
							<img src="/images/SEQIRUS.png" alt="clients" srcSet={getSrcSet[("/images/SEQIRUS.png", "/images/SEQIRUS@2x.png", "/images/SEQIRUS@3x.png")]} />
						</div>
						<div className="item">
							<img src="/images/snuh.png" alt="clients" srcSet={getSrcSet[("/images/snuh.png", "/images/snuh@2x.png", "/images/snuh@3x.png")]} />
						</div>
					</div>
				</div>
				<div className="row align-items-center">
					<div className="col-lg-6 order-lg-last text-center text-lg-left">
						<div className="title-left">
							<h3 className="top-sep">About Us</h3>
						</div>
						<p>weaverM은 급변하는 온라인 커뮤니티 환경에서 날실과 씨실을 엮듯, 우리의 기술과 현실 속에서 쌓아온 경험을 모아 새로운 가상 세계의 커뮤니티를 창조합니다.</p>
						<p>
							가상이란 상상의 공간에 현실의 경험과 소통을 구현하고자 하는 것인데, 이를 통해 무한한 가능성과 기술을 더하여 상상하는 세계의 발전을 실현하고 궁극적으로 무한한 확장을
							추구합니다.
						</p>
					</div>
					<div className="col-lg-6 d-none d-lg-block order-lg-first">
						<img src={"/images/img_01.png"} srcSet={getSrcSet[("/images/img_01.png", "/images/img_01@2x.png", "/images/img_01@3x.png")]} className="img-fluid pr-lg-4" alt="" />
					</div>
				</div>
			</div>
		</section>
	);
};
