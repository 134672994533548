/* eslint-disable */
import React from "react";
import { getSrcSet } from "../../UI/Assets";

export default (props) => {
	return (
		<section className="service-section sp-100-70" id="service">
			<div className="container">
				<div className="row">
					<div className="col-12">
						<div className="section-title title-left text-center text-lg-left">
							<h3 className="top-sep">Solution</h3>
							<p>다양한 온라인 세미나, 강의 솔루션을 바탕으로 온라인에 특화된 다양한 기능(실시간 소통, 온라인 이벤트)을 편리하고 안정적으로 제공하는 Webinar Solution</p>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-xl-4 col-md-6 col-12 mb-30">
						<div className="service-box">
							<div className="icon-box">
								<img src={"/images/online-class.png"} srcSet={getSrcSet[("/images/online-class.png", "/images/online-class@2x.png", "/images/online-class@3x.png")]} />
							</div>
							<h5 className="btm-sep">Webinar</h5>
							<p>다양한 온라인 세미나, 강의 솔루션을 바탕으로 온라인에 특화된 다양한 기능 (실시간 소통, 온라인 이벤트)을 편리하고 안정적으로 제공하는 Webinar Solution</p>
						</div>
					</div>
					<div className="col-xl-4 col-md-6 col-12 mb-30">
						<div className="service-box">
							<div className="icon-box">
								<img src={"/images/warning.png"} srcSet={getSrcSet[("/images/warning.png", "/images/warning@2x.png", "/images/warning@3x.png")]} />
							</div>
							<h5 className="btm-sep">Metaverse(VR)</h5>
							<p>
								MetaVerse의 VR을 이용하여 가상 공간에 기존의 오프라인 공간을 재현하여 참석자에게 현실감 있는 경험을 제공
								<br />
								<br />
							</p>
						</div>
					</div>
					<div className="col-xl-4 col-md-6 col-12 mb-30">
						<div className="service-box">
							<div className="icon-box">
								<img src={"/images/global-network.png"} srcSet={getSrcSet[("/images/global-network.png", "/images/global-network@2x.png", "/images/global-network@3x.png")]} />
							</div>
							<h5 className="btm-sep">Metaverse(Gather)</h5>
							<p>
								MetaVerse의 Gather을 이용하여 가상 공간에 기존의 오프라인 공간을 재현하여 참석자들 간의 Community 활성화
								<br />
								<br />
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
