import { createGlobalStyle, ThemeProvider as Provider } from "styled-components";

import "../Resources/style.css";

const theme = {
	color: {},
	layout: {},
	fontFamily: {},
};

const globalStyle = createGlobalStyle`
    * {
        outline: none !important;
    }
   

`;

export const Theme = theme;
export const ThemeProvider = Provider;
export default globalStyle;
