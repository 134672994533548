/* eslint-disable */
import React, { useEffect, useState } from "react";
import GlobalStyles, { ThemeProvider, Theme } from "./Components/GlobalStyles";
import qs from "query-string";

// App Provider
import { AppProvider } from "./Components/AppProvider";

// Utils
import { _CK, _API } from "./Modules/Utils";

import Loading from "./UI/Loading";

// Router
import Views from "./Views";

export default () => {
	const [loaded, setLoaded] = useState(false);
	const [auth, setAuth] = useState("");
	const [iam, setIam] = useState(null);

	const preLoad = async () => {
		let parsedQs = null;
		if (window.location.search) {
			parsedQs = qs.parse(window.location.search);
		}

		try {
			setLoaded(true);
		} catch (e) {}
	};

	useEffect(() => {
		preLoad();
	}, []);

	// if (!loaded) {
	// 	return (
	// 		<ThemeProvider theme={Theme}>
	// 			<Loading />
	// 			<GlobalStyles />
	// 		</ThemeProvider>
	// 	);
	// }

	return (
		<ThemeProvider theme={Theme}>
			<AppProvider auth={auth} iam={iam}>
				<Views />
				<GlobalStyles />
			</AppProvider>
		</ThemeProvider>
	);
};
