import _CK from "../Utils/cookie";
import _U from "../Utils/utilities";

const config = {};

config.isLocal = () => {
	return process.env.STAGE === "local";
};

config.getAPIKey = () => {
	switch (process.env.STAGE) {
		case "prod":
			return "8f8cd6fb85250573e6ac5448db99973a";
		default:
			return "b41837b6d4cc1e48d804b692ab9bbb9e";
	}
};

config.getAPIUrl = () => {
	switch (process.env.STAGE) {
		case "prod":
			return "https://api.weaverm.com";
		default:
			return "http://localhost:3100";
	}
};

// config.wsHost = () => {
// 	const authors = {
// 		apikey: config.getAPIKey(),
// 	};
// 	if (_CK.isLogin()) {
// 		authors.authorization = _CK.getAuth();
// 	}

// 	switch (process.env.STAGE) {
// 		case "prod":
// 			return `wss://ws.flexme.com?auth=${_U.encode(authors)}`;
// 		case "test":
// 			return `wss://ws-test.flexme.com?auth=${_U.encode(authors)}`;
// 		case "dev":
// 			return `wss://ws-dev.flexme.com?auth=${_U.encode(authors)}`;
// 		default:
// 			// return `wss://ws-dev.flexme.com?auth=${_U.encode(authors)}`;
// 			return `ws://local.flexme.com:3300?auth=${_U.encode(authors)}`;
// 	}
// };

export default config;
