import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";

import App from "./App";

import jQuery from "jquery";

import "./Resources/Plugins/bootstrap/css/bootstrap.min.css";
import "./Resources/Plugins/animate-css/animate.min.css";
import "./Resources/Plugins/slick/slick.css";
import "./Resources/Plugins/slick/slick-theme.css";
import "./Resources/Plugins/magnific-popup/magnific-popup.css";
import "./Resources/Plugins/material-design-iconic-font/css/material-design-iconic-font.min.css";
import "./Resources/fonts/flaticon/flaticon.css";

import "./Resources/scss/animation.scss";
import "./Resources/scss/nav.scss";
import "./Resources/scss/header.scss";
import "./Resources/scss/footer.scss";
import "./Resources/scss/reset.scss";
import "./Resources/scss/home.scss";

ReactDOM.render(<App />, document.getElementById("root"));
