/* eslint-disable */

import Cookies from "universal-cookie";

const cm = new Cookies();

const getCookie = (key) => {
	return cm.get(key);
};

const setCookie = (key, value, year) => {
	const param = { path: "/", sameSite: "lax" };
	if (year) {
		const date = new Date();
		date.setFullYear(date.getFullYear() + year);
		param.expires = date;
	}

	cm.set(key, value, param);
};

const setCookies = (cookies, url) => {
	const keys = Object.keys(cookies);
	for (let i in keys) {
		const k = keys[i];
		const v = cookies[k];
		cm.set(k, v, { path: "/", sameSite: "lax" });
	}
};

const getCookies = (keys) => {
	const cookies = {};
	for (let i in keys) {
		const key = keys[i];
		cookies[key] = getCookie(key);
	}
	return cookies;
};

const getAuth = () => {
	return cm.get("authorization");
};

const setAuth = (auth) => {
	cm.set("authorization", auth, { path: "/", sameSite: "lax" });
};

const clearAuth = () => {
	cm.set("authorization", "", { path: "/", sameSite: "lax" });
};

const isLogin = () => {
	if (!getAuth()) {
		return false;
	}
	return true;
};

const setCFCookies = (data) => {
	const { cfSign, cfUrl } = data;
	const url = new URL(cfUrl);
	const { host } = url;
	const names = host.split(".");
	let hostname = `.${host}`;
	if (names.length > 2) {
		hostname = `.${names[names.length - 2]}.${names[names.length - 1]}`;
	}
	const keys = Object.keys(cfSign);
	for (let i in keys) {
		const k = keys[i];
		const v = cfSign[k];
		cm.set(k, v, { path: "/", sameSite: "lax", domain: hostname });
	}
};

export default {
	set: setCookie,
	get: getCookie,
	setCookies,
	getCookies,
	getAuth,
	setAuth,
	clearAuth,
	isLogin,
	setCFCookies,
};
