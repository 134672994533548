import React, { createContext, useState } from "react";

const AppContext = createContext();
const AppProvider = ({ auth: authInProps, iam: iamInProps, children }) => {
	const [auth, setAuth] = useState(authInProps);
	const [iam, setIam] = useState(iamInProps);

	const saveAuth = (auth) => {
		setAuth(auth);
	};
	const saveIam = (iam) => {
		setIam(iam);
	};

	return <AppContext.Provider value={{ auth, saveAuth, iam, saveIam }}>{children}</AppContext.Provider>;
};

export { AppContext, AppProvider };
