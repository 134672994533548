export const getLabel = (list, value) => {
	if (!list || list.length === 0 || !value) return "";

	const f = list.filter((l) => l.value === value);
	if (f.length === 0) {
		return "";
	}
	return f[0].label;
};

export const STATUS = [
	{ label: "사용", value: "Y" },
	{ label: "사용안함", value: "N" },
];

export const getDefinedLabel = (arr, value) => {
	if (arr && value) {
		for (let i in arr) {
			if (value === arr[i].value) {
				return arr[i].label;
			}
		}
	}
	return "";
};

export const getDefinedValue = (arr, label) => {
	if (arr && label) {
		for (let i in arr) {
			if (label === arr[i].label) {
				return arr[i].value;
			}
		}
	}
	return "";
};
