/* eslint-disable */

import axios from "axios";
import swal from "sweetalert";
import { v4 as uuid } from "uuid";
import path from "path";
import moment from "moment";
import "aws-sdk/dist/aws-sdk";

import { _CFG } from "../Config";
import _CK from "./cookie";

const AWS = window.AWS;

const api = {
	apiKey: _CFG.getAPIKey(),
	apiHost: _CFG.getAPIUrl(),
};

api.get = (request) => {
	request.method = "GET";
	return api.send(request);
};
api.post = (request) => {
	request.method = "POST";
	return api.send(request);
};
api.put = (request) => {
	request.method = "PUT";
	return api.send(request);
};
api.delete = (request) => {
	request.method = "DELETE";
	return api.send(request);
};

api.send = (request) => {
	const { path, data } = request;
	let { auth, method, retry, isNoErrorNotify } = request;
	if (!auth) auth = _CK.isLogin() ? _CK.getAuth() : "";
	if (!method) method = "GET";
	if (retry === null || retry === undefined) retry = 5;
	if (!isNoErrorNotify) isNoErrorNotify = false;

	return new Promise((resolve, reject) => {
		const req = {
			method,
			url: `${api.apiHost}${path}`,
			headers: {
				apikey: api.apiKey,
				authorization: auth,
			},
			data: method.toUpperCase() !== "GET" ? data : null,
			params: method.toUpperCase() === "GET" ? data : null,
		};
		axios(req)
			.then((res) => {
				if (res.status !== 200) {
					throw new Error(res.statusText);
				}

				const { data } = res;
				if (data.error !== 0) {
					throw data;
				}

				resolve && resolve(data);
			})
			.catch((e) => {
				if (e.message === "Network Error" && retry > 0) {
					request.retry = retry - 1;
					setTimeout(() => {
						api.send(request);
					}, 500);
					return;
				}
				if (!isNoErrorNotify) {
					api.error(e, reject);
				}
			});
	});
};

api.iam = (auth) => {
	const req = {
		path: "/user/iam",
		method: "GET",
		auth: auth ? auth : _CK.getAuth(),
	};
	return api.send(req);
};

api.loadUploadKeys = () => {
	const req = {
		path: "/upload",
		method: "GET",
	};
	return api.send(req);
};

api.error = (e, reject) => {
	let message = e.message;
	if (e.error) {
		switch (e.error) {
			case -2:
				message = "API Key가 누락되었습니다.";
				break;
			case -3:
				message = "API Key를 찾을 수 없습니다.";
				break;
			case -4:
				message = "인증정보가 누락되었습니다.";
				break;
			case -5:
				message = "인증정보를 확인 할 수 없습니다.";
				break;
			case -12:
				message = "아이디를 입력해 주세요.";
				break;
			case -13:
				message = "비밀번호를 입력해 주세요.";
				break;
			case -14:
				message = "회원정보를 찾을 수 없습니다.";
				break;
			case -15:
				message = "회원상태가 올바르지 않습니다. 관리자와 상담해 주세요.";
				break;
			case -16:
				message = "로그인 후 시청가능합니다.";
				break;
		}
	}
	swal({ title: "알림", text: message }).then(() => {
		reject && reject(e);
	});
};

api.upload = ({ aws, file, path: location, prefix, security, config }) => {
	return new Promise((resolve, reject) => {
		if (!aws) {
			reject(new Error("AWS 정보를 확인해 주세요."));
			return;
		}
		if (!file) {
			reject(new Error("파일을 입력해 주세요."));
			return;
		}
		const isLocal = _CFG.isLocal();

		const s3 = new AWS.S3({
			accessKeyId: aws.accessKey,
			secretAccessKey: aws.secretKey,
			region: aws.region,
		});

		const uniqueId = uuid().replace(/-/gi, "").toLowerCase();
		const uploadPath = `${isLocal ? "TEMP/" : ""}${prefix ? `${prefix}/` : ""}${location ? location : moment().format("YYYYMMDD")}/${uniqueId}${path.extname(file.name)}`;
		s3.upload(
			{
				Bucket: aws.bucket,
				Key: uploadPath,
				Body: file,
				ContentType: file.type,
				ACL: "private",
			},
			async (err, data) => {
				if (err) {
					reject(err);
					return;
				}

				const { Bucket, Key } = data;
				const uploaded = {
					bucket: Bucket,
					path: Key,
					name: file.name,
					size: file.size,
					type: file.type,
					security,
				};
				const req = { method: "POST", path: "/upload", data: uploaded, config };
				const res = await api.send(req);
				resolve(res);
			}
		);
	});
};

export default api;
