/* eslint-disable */
import React from "react";

export default (props) => {
	return (
		<section className="section-hero" id="home">
			<div className="banner position-relative">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-12 col-12 text-center" data-wow-delay="0.2s">
							<div className="banner-text">
								<h1 className="c-white mb-3 mb-md-4">가상과 현실을 엮다</h1>
								<p className="c-white">
									우리의 경험과 상상을 기술과 엮어 이전에 없던
									<br />
									새로운 커뮤니티 공간을 만들어냅니다.
								</p>
							</div>
						</div>
						<div className="col-lg-6 col-12 d-none d-lg-block wow zoomIn" data-wow-delay="0.4s">
							<div className="banner-img"></div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
