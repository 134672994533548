/* eslint-disable */
import React, { useState } from "react";
import cx from "classnames";
import { getSrcSet } from "../../UI/Assets";

export default (props) => {
	const [tab, setTab] = useState("ALL");

	const isHide = (code) => {
		if (tab === "ALL") {
			return false;
		} else {
			if (tab !== code) {
				return true;
			}
			return false;
		}
	};

	return (
		<section className="project-section sp-100-70" id="project" style={{ backgroundColor: "#e4e4e4" }}>
			<div className="container">
				<div className="row">
					<div className="col-12">
						<div className="section-title title-left text-center text-lg-left">
							<h3 className="top-sep">Portfolio</h3>
							<p>
								온라인 세미나, 강의 솔루션 MD-Webinar Solution에 MetaVerse의 VR과 Gathertown을 이용하여 가상 공간에 기존의 오프라인 공간을 재현하여 참석자에게 현실감 있는 경험을
								제공하고, 참석자들 간의 Community 활성화
							</p>
						</div>
					</div>
				</div>
				<div className="row d-none d-md-flex">
					<div className="col-12">
						<ul className="sorting mb-60">
							<li className={cx({ "filter-btn": true, active: tab === "ALL" })} onClick={() => setTab("ALL")}>
								<span>All</span>
							</li>
							<li className={cx({ "filter-btn": true, active: tab === "WEBINAR" })} onClick={() => setTab("WEBINAR")}>
								<span>Webinar</span>
							</li>
							<li className={cx({ "filter-btn": true, active: tab === "SOCIETY" })} onClick={() => setTab("SOCIETY")}>
								<span>학회</span>
							</li>
							<li className={cx({ "filter-btn": true, active: tab === "VR" })} onClick={() => setTab("VR")}>
								<span>MetaVerse-VR</span>
							</li>
							<li className={cx({ "filter-btn": true, active: tab === "GATHER" })} onClick={() => setTab("GATHER")}>
								<span>MetaVerse-Gather</span>
							</li>
						</ul>
					</div>
				</div>
				<div className="row masonary-wrap">
					<div className={cx({ "col-lg-4": true, "col-md-6": true, "col-12": true, "port-item": true, "mb-30": true, digitalmarketing: true, hidden: isHide("WEBINAR") })}>
						<div className="project">
							<div className="proj-img">
								<img src="/images/webinar/img_01.png" alt="" srcSet={getSrcSet[("/images/webinar/img_01.png", "/images/webinar/img_01@2x.png", "/images/webinar/img_01@3x.png")]} />
								<div className="proj-overlay">
									<h5>BTCPM</h5>
									<p>
										아마존 스트리밍
										<br />
										휴대폰 로그인
										<br />
										사전등록
										<br />
										온라인 Q&amp;A
										<br />
										온라인 설문조사
										<br />
										온라인 Feedback
										<br />
										출결
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className={cx({ "col-lg-4": true, "col-md-6": true, "col-12": true, "port-item": true, "mb-30": true, digitalmarketing: true, hidden: isHide("WEBINAR") })}>
						<div className="project">
							<div className="proj-img">
								<img src="/images/webinar/img_02.png" alt="" srcSet={getSrcSet[("/images/webinar/img_02.png", "/images/webinar/img_02@2x.png", "/images/webinar/img_02@3x.png")]} />
								<div className="proj-overlay">
									<h5>BRAVE Symposium</h5>
									<p>
										아마존 스트리밍
										<br />
										휴대폰 로그인
										<br />
										사전등록
										<br />
										온라인 Q&amp;A
										<br />
										온라인 설문조사
										<br />
										온라인 Feedback
										<br />
										출결
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className={cx({ "col-lg-4": true, "col-md-6": true, "col-12": true, "port-item": true, "mb-30": true, digitalmarketing: true, hidden: isHide("WEBINAR") })}>
						<div className="project">
							<div className="proj-img">
								<img src="/images/webinar/img_03.png" alt="" srcSet={getSrcSet[("/images/webinar/img_03.png", "/images/webinar/img_03@2x.png", "/images/webinar/img_03@3x.png")]} />
								<div className="proj-overlay">
									<h5>Cannon VET Academy</h5>
									<p>
										아마존 스트리밍
										<br />
										휴대폰 로그인
										<br />
										사전등록
										<br />
										온라인 Q&amp;A
										<br />
										온라인 설문조사
										<br />
										온라인 Feedback
										<br />
										출결
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className={cx({ "col-lg-4": true, "col-md-6": true, "col-12": true, "port-item": true, "mb-30": true, digitalmarketing: true, hidden: isHide("WEBINAR") })}>
						<div className="project">
							<div className="proj-img">
								<img src="/images/webinar/img_04.png" alt="" srcSet={getSrcSet[("/images/webinar/img_04.png", "/images/webinar/img_04@2x.png", "/images/webinar/img_04@3x.png")]} />
								<div className="proj-overlay">
									<h5>CTIF</h5>
									<p>
										아마존 스트리밍
										<br />
										휴대폰 로그인
										<br />
										사전등록
										<br />
										온라인 Q&amp;A
										<br />
										온라인 설문조사
										<br />
										온라인 Feedback
										<br />
										출결
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className={cx({ "col-lg-4": true, "col-md-6": true, "col-12": true, "port-item": true, "mb-30": true, digitalmarketing: true, hidden: isHide("WEBINAR") })}>
						<div className="project">
							<div className="proj-img">
								<img src="/images/webinar/img_05.png" alt="" srcSet={getSrcSet[("/images/webinar/img_05.png", "/images/webinar/img_05@2x.png", "/images/webinar/img_05@3x.png")]} />
								<div className="proj-overlay">
									<h5>2022 東亜ST Kick-off Meeting</h5>
									<p>
										아마존 스트리밍
										<br />
										휴대폰 로그인
										<br />
										사전등록
										<br />
										온라인 Q&amp;A
										<br />
										온라인 설문조사
										<br />
										온라인 Feedback
										<br />
										출결
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className={cx({ "col-lg-4": true, "col-md-6": true, "col-12": true, "port-item": true, "mb-30": true, digitalmarketing: true, hidden: isHide("WEBINAR") })}>
						<div className="project">
							<div className="proj-img">
								<img src="/images/webinar/img_06.png" alt="" srcSet={getSrcSet[("/images/webinar/img_06.png", "/images/webinar/img_06@2x.png", "/images/webinar/img_06@3x.png")]} />
								<div className="proj-overlay">
									<h5>2022 Partfalio Symposium</h5>
									<p>
										아마존 스트리밍
										<br />
										휴대폰 로그인
										<br />
										사전등록
										<br />
										온라인 Q&amp;A
										<br />
										온라인 설문조사
										<br />
										온라인 Feedback
										<br />
										출결
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className={cx({ "col-lg-4": true, "col-md-6": true, "col-12": true, "port-item": true, "mb-30": true, digitalmarketing: true, hidden: isHide("WEBINAR") })}>
						<div className="project">
							<div className="proj-img">
								<img src="/images/webinar/img_07.png" alt="" srcSet={getSrcSet[("/images/webinar/img_07.png", "/images/webinar/img_07@2x.png", "/images/webinar/img_07@3x.png")]} />
								<div className="proj-overlay">
									<h5>2021 Partfalio Symposium</h5>
									<p>
										아마존 스트리밍
										<br />
										휴대폰 로그인
										<br />
										사전등록
										<br />
										온라인 Q&amp;A
										<br />
										온라인 설문조사
										<br />
										온라인 Feedback
										<br />
										출결
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className={cx({ "col-lg-4": true, "col-md-6": true, "col-12": true, "port-item": true, "mb-30": true, digitalmarketing: true, hidden: isHide("WEBINAR") })}>
						<div className="project">
							<div className="proj-img">
								<img src="/images/webinar/img_08.png" alt="" srcSet={getSrcSet[("/images/webinar/img_08.png", "/images/webinar/img_08@2x.png", "/images/webinar/img_08@3x.png")]} />
								<div className="proj-overlay">
									<h5>PRIDE Symposium</h5>
									<p>
										아마존 스트리밍
										<br />
										휴대폰 로그인
										<br />
										사전등록
										<br />
										온라인 Q&amp;A
										<br />
										온라인 설문조사
										<br />
										온라인 Feedback
										<br />
										출결
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className={cx({ "col-lg-4": true, "col-md-6": true, "col-12": true, "port-item": true, "mb-30": true, digitalmarketing: true, hidden: isHide("WEBINAR") })}>
						<div className="project">
							<div className="proj-img">
								<img src="/images/webinar/img_09.png" alt="" srcSet={getSrcSet[("/images/webinar/img_09.png", "/images/webinar/img_09@2x.png", "/images/webinar/img_09@3x.png")]} />
								<div className="proj-overlay">
									<h5>시퀴러스코리아 출범 기자간담회</h5>
									<p>
										아마존 스트리밍
										<br />
										휴대폰 로그인
										<br />
										사전등록
										<br />
										온라인 Q&amp;A
										<br />
										온라인 설문조사
										<br />
										온라인 Feedback
										<br />
										출결
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className={cx({ "col-lg-4": true, "col-md-6": true, "col-12": true, "port-item": true, "mb-30": true, digitalmarketing: true, hidden: isHide("WEBINAR") })}>
						<div className="project">
							<div className="proj-img">
								<img src="/images/webinar/img_10.png" alt="" srcSet={getSrcSet[("/images/webinar/img_10.png", "/images/webinar/img_10@2x.png", "/images/webinar/img_10@3x.png")]} />
								<div className="proj-overlay">
									<h5>SPECIALTY Symposium</h5>
									<p>
										아마존 스트리밍
										<br />
										휴대폰 로그인
										<br />
										사전등록
										<br />
										온라인 Q&amp;A
										<br />
										온라인 설문조사
										<br />
										온라인 Feedback
										<br />
										출결
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className={cx({ "col-lg-4": true, "col-md-6": true, "col-12": true, "port-item": true, "mb-30": true, digitalmarketing: true, hidden: isHide("SOCIETY") })}>
						<div className="project">
							<div className="proj-img">
								<img src="/images/society/img_19.png" alt="" srcSet={getSrcSet[("/images/society/img_19.png", "/images/society/img_19@2x.png", "/images/society/img_19@3x.png")]} />
								<div className="proj-overlay">
									<h5>서울대병원 희귀질환센터</h5>
									<p>
										아마존 스트리밍
										<br />
										휴대폰 로그인
										<br />
										온라인 Q&amp;A, 설문조사, Feedback
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className={cx({ "col-lg-4": true, "col-md-6": true, "col-12": true, "port-item": true, "mb-30": true, digitalmarketing: true, hidden: isHide("SOCIETY") })}>
						<div className="project">
							<div className="proj-img">
								<img src="/images/society/img_20.png" alt="" srcSet={getSrcSet[("/images/society/img_20.png", "/images/society/img_20@2x.png", "/images/society/img_20@3x.png")]} />
								<div className="proj-overlay">
									<h5>서울대병원 발달장애인.행동발달증진센터 중앙지원단 심포지엄</h5>
									<p>
										아마존 스트리밍
										<br />
										로그인 없음
										<br />
										강의록 다운로드
										<br />
										온라인 Q&amp;A, 설문조사, Feedback
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className={cx({ "col-lg-4": true, "col-md-6": true, "col-12": true, "port-item": true, "mb-30": true, digitalmarketing: true, hidden: isHide("SOCIETY") })}>
						<div className="project">
							<div className="proj-img">
								<img src="/images/society/img_17.png" alt="" srcSet={getSrcSet[("/images/society/img_17.png", "/images/society/img_17@2x.png", "/images/society/img_17@3x.png")]} />
								<div className="proj-overlay">
									<h5>4th Amgen Science Academy</h5>
									<p>
										아마존 스트리밍
										<br />
										휴대폰 로그인
										<br />
										사전등록
										<br />
										온라인 Q&amp;A, 설문조사, Feedback
										<br />
										5개 세션
										<br />
										다국어 스트리밍
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className={cx({ "col-lg-4": true, "col-md-6": true, "col-12": true, "port-item": true, "mb-30": true, digitalmarketing: true, hidden: isHide("SOCIETY") })}>
						<div className="project">
							<div className="proj-img">
								<img src="/images/society/img_18.png" alt="" srcSet={getSrcSet[("/images/society/img_18.png", "/images/society/img_18@2x.png", "/images/society/img_18@3x.png")]} />
								<div className="proj-overlay">
									<h5>Korea bone Academy 2021</h5>
									<p>
										아마존 스트리밍
										<br />
										휴대폰 로그인
										<br />
										사전등록
										<br />
										온라인 Q&amp;A, 설문조사, Feedback
										<br />
										다국어 스트리밍
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className={cx({ "col-lg-4": true, "col-md-6": true, "col-12": true, "port-item": true, "mb-30": true, digitalmarketing: true, hidden: isHide("SOCIETY") })}>
						<div className="project">
							<div className="proj-img">
								<img src="/images/society/img_21.png" alt="" srcSet={getSrcSet[("/images/society/img_21.png", "/images/society/img_21@2x.png", "/images/society/img_21@3x.png")]} />
								<div className="proj-overlay">
									<h5>2021년도 치주과학회 종합학술대회</h5>
									<p>
										E-booth(2D)
										<br />
										각부스별 홈페이지 링크
									</p>
									<a
										className="pop-btn"
										onClick={(e) => {
											e.stopPropagation();
											window.open("https://keymedi-ebooth.md-webinar.com/");
										}}
									>
										<i className="zmdi zmdi-zoom-in"></i>
									</a>
								</div>
							</div>
						</div>
					</div>

					<div className={cx({ "col-lg-4": true, "col-md-6": true, "col-12": true, "port-item": true, "mb-30": true, digitalmarketing: true, hidden: isHide("VR") })}>
						<div className="project">
							<div className="proj-img">
								<img src="/images/vr/img_13.png" alt="" srcSet={getSrcSet[("/images/vr/img_13.png", "/images/vr/img_13@2x.png", "/images/vr/img_13@3x.png")]} />
								<div className="proj-overlay">
									<h5>Canon VR BOOTH </h5>
									<p>
										사전등록
										<br />
										휴대폰 로그인
										<br />
										미니맵
										<br />
										상하좌우 이동
										<br />
										컨텐츠 삽입(이미지, PDF, 동영상, 유튜브링크)
										<br />
										방문자 이동경로 추적
									</p>
									<a
										className="pop-btn"
										onClick={(e) => {
											e.stopPropagation();
											window.open("https://cmskvirtual.co.kr");
										}}
									>
										<i className="zmdi zmdi-zoom-in"></i>
									</a>
								</div>
							</div>
						</div>
					</div>
					<div className={cx({ "col-lg-4": true, "col-md-6": true, "col-12": true, "port-item": true, "mb-30": true, digitalmarketing: true, hidden: isHide("VR") })}>
						<div className="project">
							<div className="proj-img">
								<img src="/images/vr/img_14.png" alt="" srcSet={getSrcSet[("/images/vr/img_14.png", "/images/vr/img_14@2x.png", "/images/vr/img_14@3x.png")]} />
								<div className="proj-overlay">
									<h5>Canon VR BOOTH</h5>
									<p>
										미니맵
										<br />
										상하좌우 이동
										<br />
										컨텐츠 삽입(이미지, PDF, 동영상, 유튜브링크)
										<br />
										방문자 이동경로 추적
									</p>
									<a
										className="pop-btn"
										onClick={(e) => {
											e.stopPropagation();
											window.open("https://canon-ultrasound.co.kr");
										}}
									>
										<i className="zmdi zmdi-zoom-in"></i>
									</a>
								</div>
							</div>
						</div>
					</div>
					<div className={cx({ "col-lg-4": true, "col-md-6": true, "col-12": true, "port-item": true, "mb-30": true, digitalmarketing: true, hidden: isHide("VR") })}>
						<div className="project">
							<div className="proj-img">
								<img src="/images/vr/img_15.png" alt="" srcSet={getSrcSet[("/images/vr/img_15.png", "/images/vr/img_15@2x.png", "/images/vr/img_15@3x.png")]} />
								<div className="proj-overlay">
									<h5>Canon VR BOOTH</h5>
									<p>
										미니맵
										<br />
										상하좌우 이동
										<br />
										컨텐츠 삽입(이미지, PDF, 동영상, 유튜브링크)
										<br />
										방문자 이동경로 추적
									</p>
									<a
										className="pop-btn"
										onClick={(e) => {
											e.stopPropagation();
											window.open("https://canon-ultrasound.co.kr");
										}}
									>
										<i className="zmdi zmdi-zoom-in"></i>
									</a>
								</div>
							</div>
						</div>
					</div>
					<div className={cx({ "col-lg-4": true, "col-md-6": true, "col-12": true, "port-item": true, "mb-30": true, digitalmarketing: true, hidden: isHide("VR") })}>
						<div className="project">
							<div className="proj-img">
								<img src="/images/vr/img_16.png" alt="" srcSet={getSrcSet[("/images/vr/img_16.png", "/images/vr/img_16@2x.png", "/images/vr/img_16@3x.png")]} />
								<div className="proj-overlay">
									<h5>제20차 회원학회 임원 아카데미</h5>
									<p>
										사전등록
										<br />
										이메일 로그인
										<br />
										상하좌우 이동
										<br />
										2개 세션
										<br />
										다국어 스트리밍
										<br />
										참석자 출결
										<br />
										온라인 Q&amp;A, 설문조사, Feedback
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className={cx({ "col-lg-4": true, "col-md-6": true, "col-12": true, "port-item": true, "mb-30": true, digitalmarketing: true, hidden: isHide("GATHER") })}>
						<div className="project">
							<div className="proj-img">
								<img src="/images/gather/img_11.png" alt="" srcSet={getSrcSet[("/images/gather/img_11.png", "/images/gather/img_11@2x.png", "/images/gather/img_11@3x.png")]} />
								<div className="proj-overlay">
									<h5>Gather를 이용한 e-booth</h5>
									<p>
										Gather통해 로그인
										<br />
										상하좌우 이동
										<br />
										부스별 컨텐츠 삽입(이미지, PDF, 동영상, 유튜브링크)
										<br />
										화상회의, 채팅
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className={cx({ "col-lg-4": true, "col-md-6": true, "col-12": true, "port-item": true, "mb-30": true, digitalmarketing: true, hidden: isHide("GATHER") })}>
						<div className="project">
							<div className="proj-img">
								<img src="/images/gather/img_12.png" alt="" srcSet={getSrcSet[("/images/gather/img_12.png", "/images/gather/img_12@2x.png", "/images/gather/img_12@3x.png")]} />
								<div className="proj-overlay">
									<h5>Gather를 이용한 Meeting</h5>
									<p>
										Gather통해 로그인
										<br />
										상하좌우 이동
										<br />
										부스별 컨텐츠 삽입(이미지, PDF, 동영상, 유튜브링크)
										<br />
										화상회의, 채팅
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
