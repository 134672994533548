/* eslint-disable */
import React, { useEffect } from "react";
import "../Resources/main.js";
import { getSrcSet } from "../UI/Assets";

export default (props) => {
	useEffect(() => {}, []);

	return (
		<>
			<Header />
			{props.children}
			<Footer />
		</>
	);
};

const Header = () => {
	return (
		<>
			<div id="preloader">
				<div className="blobs">
					<div className="blob-center"></div>
					<div className="blob"></div>
					<div className="blob"></div>
					<div className="blob"></div>
					<div className="blob"></div>
					<div className="blob"></div>
					<div className="blob"></div>
				</div>
			</div>
			<header className="header position-relative">
				<div className="navigation-bar" id="affix">
					<div className="container">
						<nav className="navbar navbar-expand-lg navbar-light navbar-reset">
							<a className="logo" href="index.html">
								<img className="logo-default" src="/images/Logo.png" srcSet={getSrcSet(["/images/Logo.png", "/images/Logo@2x.png", "/images/Logo@3x.png"])} />
							</a>
							<button
								className="navbar-toggler border-0 p-0"
								type="button"
								data-toggle="collapse"
								data-target="#theme-navbar"
								aria-controls="theme-navbar"
								aria-expanded="false"
								aria-label="Toggle navigation"
							>
								<span className="navbar-toggler-lines"></span>
							</button>
							<div className="collapse navbar-collapse" id="theme-navbar">
								<ul className="navbar-nav ml-auto">
									<li className="nav-item">
										<a className="nav-link" href="#home">
											Home
										</a>
									</li>
									<li className="nav-item">
										<a className="nav-link" href="#about">
											About
										</a>
									</li>
									<li className="nav-item">
										<a className="nav-link" href="#service">
											Solution
										</a>
									</li>
									<li className="nav-item">
										<a className="nav-link" href="#project">
											Portfolio
										</a>
									</li>
									<li className="nav-item">
										<a className="nav-link" href="#contact">
											Contact Us
										</a>
									</li>
								</ul>
							</div>
						</nav>
					</div>
				</div>
			</header>
		</>
	);
};

const Footer = () => {
	return (
		<>
			<footer className="bg-white footer">
				<div className="container">
					<div className="footer-top">
						<div className="row">
							<div className="col-md-6 col-xl-3 mb-30">
								<div className="mb-30">
									<img
										className="logo-footer"
										src="/images/bottom_logo.png"
										srcSet={getSrcSet(["/images/bottom_logo.png", "/images/bottom_logo@2x.png", "/images/bottom_logo@3x.png"])}
										alt=""
									/>
								</div>
							</div>
							<div className="col-md-6 col-xl-9 mb-30 text">
								서비스명 : 위버엠 | 회사명 : ㈜디몬엠디 | 사업자등록번호 : 613-88-01856 | 대표 : 도진명
								<br />
								주소 : 서울특별시 강동구 양재대로112길 16 302호
								<br />
								E-MAIL : jmdo@dmon9.com
								<br />
								<br />
								COPYRIGHT ㈜주식회사 디몬엠디. ALL RIGHTS RESERVED.
							</div>
						</div>
					</div>
				</div>
			</footer>
		</>
	);
};
