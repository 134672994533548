/* eslint-disable */
import React from "react";

import Layout from "../UI/Layout";

import { VisualView, AboutView, ServiceView, ProjectView, CounterView } from "../Components/Main";
import { TeamView, TestimonialsView, PricingView, BlogView, ContactView, CTAView } from "../Components/Main";

export default (props) => {
	return (
		<Layout>
			<VisualView />
			<AboutView />
			<ServiceView />
			<ProjectView />
			{/* <CounterView />
			<TeamView />
			<TestimonialsView />
			<PricingView />
			<BlogView /> */}
			<ContactView />
			{/* <CTAView /> */}
		</Layout>
	);
};
